<template>
  <div class="flex min-h-screen w-full flex-col items-center justify-center">
    <Navigation class="hidden xl:flex" />
    <NavigationMobile class="xl:hidden" />
    <section class="flex grow items-center justify-center">
      <div class="max-w-[55rem] px-10 py-32">
        <h1 class="text-center text-3xl font-bold">
          {{ error.statusCode }}
        </h1>
        <p class="title">{{ errorText }}</p>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script setup>
import { useMainStore } from '~/stores/main'
const { $patch } = useMainStore()
const mainStore = useMainStore()

const props = defineProps({
  error: Object,
  required: true,
})

const errorText = computed(() => {
  switch (props.error.statusCode) {
    case 404:
      return 'The page you are looking for was not found.'
    case 500:
      return 'An internal server error has occurred.'
    case 503:
      return 'The service is currently unavailable. Please try again later.'
    default:
      return 'Oops, something went wrong. Please try again.'
  }
})

// INITIAL
if (!mainStore.navigation?.length) {
  const { data } = await useAsyncGql({
    operation: 'NavigationAndGlobals',
  })

  if (data.value) {
    $patch({
      navigation: data.value.navigation?.tree,
      footer: data.value.footer?.tree,
      general: data.value.general,
      teaser_registration_defaults: data.value.teaser_registration_defaults,
      social_media: data.value.social_media?.social_media_links,
      seo_defaults: data.value.seo_defaults,
    })
  } else {
    console.error('Could not fetch navigation and globals in app.vue')
  }
}
</script>
